import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import {withRouter} from 'react-router-dom';
import store from '../redux/store';
// import Custom Components
import Header from './common/header';
import Footer from './common/footer';
import MobileMenu from './common/mobile-menu';
// import Utils
import {mobileMenu, preventProductDefault, removePreventProductDefault, stickyHeaderHandler} from '../utils';
// import Actions
import {closeQuickViewModal} from '../redux/actions';
import AddToCartModal from './AddToCartModal';

// import data

function App(props) {

  return (
    <>

      {/*<div id="wrapperParallax" className="wrapper clearfix">*/}
      <div className="wrapper clearfix">
        <Header />
        {
          props.children
        }
        <Footer/>
      </div>

      {/*<div className="page-wrapper">*/}
      {/*  <Header container={container} urls={prevPath}/>*/}
      {/*  <div className={'main-container'} ref={mainContainer}>*/}
      {/*    {*/}
      {/*      props.children*/}
      {/*    }*/}
      {/*  </div>*/}

      {/*  <Footer container={container} footerEle={footerEle}/>*/}

      {/*  <ToastContainer autoClose={3000} className="toast-container"/>*/}

      {/*  <AddToCartModal/>*/}
      {/*</div>*/}

      {/*<MobileMenu/>*/}

    </>
  );
}

export default withRouter(App);

