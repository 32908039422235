import React, { useEffect,useState } from 'react';
import { useAppState } from "../../contexts/app/app.provider";
import {withApolloClient} from "../../components/withApolloClient";
import { CONTACT_US } from "../../graphql/mutation/contactUs";

function Footer(props) {

  const pct = useAppState('pct');
  const companyName = useAppState('companyName');
  const socialLinks = useAppState('socialLinks');
  const { container = "container" } = props;

  const socialNameIconMapper = {
    facebook: "facebook",
    twitter: "twitter",
    pinterest: "pinterest-p",
    instagram: "instagram",
    linkedinS: "linkedin-square",
    youtube: "youtube-play",
    whatsapp: "whatsapp",
  }
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const onContactSubmit = () => {
    setIsProcessing(true);
    const { client } = props;
    client.mutate({
      mutation: CONTACT_US,
      variables: {
        name: name.trim(),
        email: (email || "").trim().toLowerCase(),
        message: message.trim(),
      }
    }).then(async data => {
      setName('');
      setEmail('');
      setMessage('');
      setSuccessMsg('We\'ve received your message. Someone from our team will contact you soon');
    }).catch(e => {

    }).finally((e) => {
      setIsProcessing(false);
    });

  }

  useEffect(() => {
    var $ = window.jQuery;
    var siteContent = $("#wrapperParallax");
    var contentParallax = $(".contentParallax");
    var siteFooter = $("#footerParallax");
    var siteFooterHeight = siteFooter.height();
    siteContent.css({
      "margin-bottom": siteFooterHeight
    });
  }, []);

  return (
    <>
      {/*<footer id="footerParallax" className="footer footer-2">*/}
      <footer className="footer" style={{ marginTop: 'auto' }}>
        <div className="container pr-40 pl-40">
          <div className="footer-widget">
            <div className="row ">
              <div
                className="col-sm-12 col-md-12 col-lg-4  widget--logo text-center-xs mb-4 d-flex justify-content-center">
                <div className='d-block'>

                  <div className="widget--content">
                    <div
                      className="  align-items-center d-flex flex-row justify-content-center flex-wrap mb-15">
                      <img
                        src={`${process.env.CLOUD_FRONT_URL}${pct.logo}`}
                        alt={companyName}
                        style={{
                          height: '60px', marginRight: '15px', display: 'block',
                          maxWidth: '270px',
                          objectFit: 'contain'
                        }}
                      /> <h6 className={'footer-company-name'}>{companyName}</h6>
                    </div>
                  </div>
                  <div className='widget--social'>

                    <div className="widget--content">
                      <div className="social--icons">
                        <div className="widget-title">
                          Follow Us On Social
                        </div>
                        {
                          socialLinks && socialLinks.length ?
                            <div className="pt-1">
                              {
                                socialLinks.map((it, index) => {

                                  if (!socialNameIconMapper[it.name]) {
                                    return null;
                                  }

                                  return <a key={index} className={it.name} href={it.url} target="_blank">
                                    <i className={`fa fa-${socialNameIconMapper[it.name]}`}></i>
                                  </a>
                                })
                              }
                            </div>
                            : ''
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-12 col-md-6 col-lg-4  text-center-xs widget--contact-info mb-4 d-flex  justify-content-center">
                <div className="widget--content">
                  <ul className="list-unstyled">
                    <li className="widget-title">Contact</li>
                    <li><a href={`mailto:anuraj@anurajexports.com`}>anuraj@anurajexports.com</a></li>
                    <li><a href={`tel:+91 5912990177`}>+91 5912990177</a></li>
                    <li style={{ color: '#000' }}>Office Hours : 9:30 am to 6:00 pm<br></br> Closed on Sunday</li>
                    {/* {
                      pct.email && <li><a href={`mailto:${pct.email}`}>{pct.email}</a></li>
                    }
                    {
                      pct.phone && <li><a href={`tel:${pct.phone}`}>{pct.phone}</a></li>
                    } */}
                  </ul>
                </div>
              </div>

              {/*<div className="col-sm-12 col-md-6 col-lg-3  text-center-xs widget--links">*/}
              {/*  <div className="widget--content">*/}
              {/*    <ul className="list-unstyled">*/}
              {/*      <li><a href="page-about-1.html">About Us </a></li>*/}
              {/*      <li><a href="page-privacy.html">Privacy Policy</a></li>*/}
              {/*      <li><a href="page-terms.html">Term & Conditions</a></li>*/}
              {/*      <li><a href="#">Help</a></li>*/}
              {/*    </ul>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div
                className="col-sm-12 col-md-6 col-lg-4 text-center-xs widget--social mb-4 d-flex  justify-content-center">
                <section className="p-0">
                  <div className="container">
                    {/* <h5 className="text-center pt-40 border-top">Connect with us</h5> */}
                    <form className="form-horizontal enquiry-form" onSubmit={(e) => {
                      e.preventDefault();
                      !isProcessing ? onContactSubmit() : '';
                    }}>
                      <fieldset>
                        <div className='row'>
                          <div className="form-group col-sm-12">
                            {/*<label className="control-label" htmlFor="NAME">Name</label>*/}
                            <input id="name" name="name" placeholder="Name" className="form-control input-md" type="text"
                              value={name}
                              onChange={({ target: { value } }) => {
                                setName(value);
                              }}
                              required />
                          </div>

                          <div className="form-group col-sm-12">
                            <input id="email" name="email" placeholder="Email" className="form-control input-md"
                              onChange={({ target: { value } }) => {
                                setEmail(value);
                              }}
                              value={email}
                              required
                              type="email" />
                          </div>

                          <div className="form-group col-sm-12">
                            <textarea
                              name="message"
                              id="message"
                              cols="30"
                              rows="4"
                              className="form-control"
                              required
                              placeholder="Message"
                              style={{ height: 'auto' }}
                              value={message}
                              onChange={({ target: { value } }) => {
                                setMessage(value);
                              }}
                            />
                          </div>

                        </div>
                        <div className="row">
                          <div className="form-group col-sm-12">
                            <button
                              id="submit"
                              name="submit"
                              type="submit"
                              className="btn btn-dark btn--secondary"
                              disabled={isProcessing}
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                        {
                          successMsg ?
                            <div className="row">
                              <div className="col-sm-12 text-success">
                                {successMsg}
                              </div>
                            </div>
                            : ''
                        }
                      </fieldset>
                    </form>
                  </div>
                </section>
              </div>

            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="footer--copyright text-center">
                  <span>
                    Copyright © {(new Date()).getFullYear()} {companyName}. All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>

          </div>

        </div>

      </footer>

      {
        false && <footer className="footer footer-2" ref={props.footerEle}>
          {/*<div className="footer-middle">*/}
          {/*    <div className={ container }>*/}
          {/*        <div className="row">*/}
          {/*            <div className="col-sm-12 col-lg-4">*/}
          {/*                <div className="widget widget-about">*/}
          {/*                    <img src={ `${process.env.PUBLIC_URL}/assets/images/logo-footer.png` } className="footer-logo" alt="Footer Logo" width="105" height="25" />*/}
          {/*                    <p>Praesent dapibus, neque id cursus ucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus. </p>*/}

          {/*                    <div className="widget-about-info">*/}
          {/*                        <div className="row">*/}
          {/*                            <div className="col-sm-6 col-md-4">*/}
          {/*                                <span className="widget-about-title">Got Question? Call us 24/7</span>*/}
          {/*                                <Link to="tel:123456789">+0123 456 789</Link>*/}
          {/*                            </div>*/}
          {/*                            <div className="col-sm-6 col-md-8">*/}
          {/*                                <span className="widget-about-title">Payment Method</span>*/}
          {/*                                <figure className="footer-payments">*/}
          {/*                                    <img src={ `${process.env.PUBLIC_URL}/assets/images/payments.png` } alt="Payment methods" width="272" height="20" />*/}
          {/*                                </figure>*/}
          {/*                            </div>*/}
          {/*                        </div>*/}
          {/*                    </div>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-4 col-lg-2">*/}
          {/*                <div className="widget">*/}
          {/*                    <h4 className="widget-title">Useful Links</h4>*/}

          {/*                    <ul className="widget-list">*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/about` }>About {companyName}</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/about` }>How to shop on {companyName}</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/faq` }>FAQ</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/contact` }>Contatc us</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/login` }>Log in</Link></li>*/}
          {/*                    </ul>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-4 col-lg-2">*/}
          {/*                <div className="widget">*/}
          {/*                    <h4 className="widget-title">Customer Service</h4>*/}

          {/*                    <ul className="widget-list">*/}
          {/*                        <li><Link to="#">Payment Methods</Link></li>*/}
          {/*                        <li><Link to="#">Money-back guarantee!</Link></li>*/}
          {/*                        <li><Link to="#">Returns</Link></li>*/}
          {/*                        <li><Link to="#">Shipping</Link></li>*/}
          {/*                        <li><Link to="#">Terms and conditions</Link></li>*/}
          {/*                        <li><Link to="#">Privacy Policy</Link></li>*/}
          {/*                    </ul>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-4 col-lg-2">*/}
          {/*                <div className="widget">*/}
          {/*                    <h4 className="widget-title">My Account</h4>*/}

          {/*                    <ul className="widget-list">*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/login` }>Log in</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/cart` }>View Cart</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/shop/wishlist` }>My Wishlist</Link></li>*/}
          {/*                        <li><Link to="#">Track My Order</Link></li>*/}
          {/*                        <li><Link to="#">Help</Link></li>*/}
          {/*                    </ul>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-6 col-lg-2">*/}
          {/*                <div className="widget widget-newsletter">*/}
          {/*                    <h4 className="widget-title">Sign Up To Newsletter</h4>*/}

          {/*                    <p>Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan.</p>*/}

          {/*                    <form action="#">*/}
          {/*                        <div className="input-group">*/}
          {/*                            <input type="email" className="form-control" placeholder="Enter your Email Address" aria-label="Email Adress" required />*/}
          {/*                            <div className="input-group-append">*/}
          {/*                                <button className="btn btn-dark" type="submit"><i className="icon-long-arrow-right"></i></button>*/}
          {/*                            </div>*/}
          {/*                        </div>*/}
          {/*                    </form>*/}
          {/*                </div>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}

          <div className="footer-bottom">
            <div className={container}>
              <p className="footer-copyright">Copyright © {(new Date()).getFullYear()} {companyName}. All Rights
                Reserved.</p>
              {/*<ul className="footer-menu">*/}
              {/*    <li><Link to="#">Terms Of Use</Link></li>*/}
              {/*    <li><Link to="#">Privacy Policy</Link></li>*/}
              {/*</ul>*/}

              {/*<div className="social-icons social-icons-color">*/}
              {/*    <span className="social-label">Social Media</span>*/}
              {/*    <a href="https://www.facebook.com" className="social-icon social-facebook" rel="noopener noreferrer" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>*/}
              {/*    <a href="https://twitter.com" className="social-icon social-twitter" rel="noopener noreferrer" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>*/}
              {/*    <a href="https://instagram.com" className="social-icon social-instagram" rel="noopener noreferrer" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>*/}
              {/*    <a href="https://youtube.com" className="social-icon social-youtube" rel="noopener noreferrer" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>*/}
              {/*    <a href="https://pinterest.com" className="social-icon social-pinterest" rel="noopener noreferrer" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>*/}
              {/*</div>*/}
            </div>
          </div>
        </footer>
      }
    </>
  );
}

export default withApolloClient(React.memo(Footer))