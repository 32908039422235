import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch, useLocation, withRouter} from "react-router-dom";
import Layout from "../components/app";
import ErrorPage from "./404/404";
import {useAppState} from "../contexts/app/app.provider";
import {AuthContext} from "../contexts/auth/auth.context";
import ReactGA from 'react-ga';
import jwt from "jsonwebtoken";
import {withApolloClient} from "../components/withApolloClient";
import {VERIFY_USER} from "../graphql/query/check.query";
import CookieConsent from "react-cookie-consent";

const HomePage = React.lazy(() => import( './home-route.js' ));
const ProductsPage = React.lazy(() => import( './products/product-route' ));
const LoginPage = React.lazy(() => import( './login/login-route' ));
const SignUpPage = React.lazy(() => import( './sign-up/sign-up-route' ));
const RequestReviewPendingPage = React.lazy(() => import( './request-review-pending/request-review-pending-route' ));
const RequestReviewRejectedPage = React.lazy(() => import( './request-review-rejected/request-review-rejected-route' ));
const CollectionPages = React.lazy(() => import( './collection/collections-route' ));
const VirtualShowroomPages = React.lazy(() => import( './virtualShowroom/virtual-showroom-route' ));
const ShopTheLookPages = React.lazy(() => import( './shopTheRoom/shop-the-room-route' ));
const QuotationPages = React.lazy(() => import( './quotation/quote-route' ));
const CartPages = React.lazy(() => import( './cart/cart-route' ));
const ForgotPasswordPage = React.lazy(() => import( './forgot-password/forgot-password-route' ));
const AboutPage = React.lazy(() => import( './about/about-route' ));
const ContactPage = React.lazy(() => import( './contact/contact-route' ));
const EventPage = React.lazy(() => import( './event/event-route' ));
const WishListPages = React.lazy(() => import( './wishlist/wishlist-route' ));
const QuotationSubmittedPage = React.lazy(() => import( './quotation-submitted/quotation-submitted-route' ));
const PasscodeVerifiedPage = React.lazy(() => import( './passcode-verified/passcode-verified-route' ));
const PasscodeSentPage = React.lazy(() => import( './passcode-sent/passcode-sent-route' ));

function GuestRoute({children, ...rest}) {

  // const appAuthenticated = useAppState('appAuthenticated');
  // if (!appAuthenticated) {
  //   return (
  //     <Route
  //       {...rest}
  //       render={({location}) =>
  //         (
  //           <Redirect
  //             to={{
  //               pathname: '/404',
  //               state: {from: location},
  //             }}
  //           />
  //         )
  //       }
  //     />
  //   )
  // }
  const pct = useAppState('pct');
  const isAuthNeeded = pct && pct.customerLoginPreferences && pct.customerLoginPreferences.loginRequired;
  const {
    authState: {isAuthenticated},
    authDispatch,
  } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({location}) =>
        (
          children
        )
      }
    />
  );

  // return (
  //   <Route
  //     {...rest}
  //     render={({location}) =>
  //       !isAuthNeeded || isAuthenticated ? (
  //         children
  //       ) : (
  //         <Redirect
  //           to={{
  //             pathname: '/login',
  //             state: {from: location},
  //           }}
  //         />
  //       )
  //     }
  //   />
  // );
}

function PrivateRoute({children, ...rest}) {
  const {
    authState: {isAuthenticated},
    authDispatch,
  } = React.useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({location}) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}

const BackToTop = React.memo(() => {
  const [visible, setVisible] = useState(false)
  let scrollVisibleLimit = 200;
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > scrollVisibleLimit) {
      setVisible(true)
    } else if (scrolled <= scrollVisibleLimit) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    () => window.removeEventListener('scroll', toggleVisible);
  }, []);

  return <button
    className={'btn btn-sm scrollToTop'}
    style={{display: visible ? 'inline' : 'none'}}
    onClick={scrollToTop}
  >
    <i className="fa fa-angle-up font-40"/>
  </button>;

});

const CookiePopup = React.memo(({pct}) => {

  return <div className="custom-cookie-container">
    <CookieConsent
      location="top"
      buttonText="Accept Cookies"
      enableDeclineButton
      declineButtonText="Decline"
      cookieName={`cookie-${pct.storeFrontSettings && pct.storeFrontSettings.domain}`}
      overlayStyle={{zIndex: 1032}}
      contentStyle={{
        zIndex: 1032,
        top: "10%",
        left: "5%",
        right: "5%",
      }}
      style={{
        background: "#fff",
        color: "#2B373B",
        zIndex: 1032
      }}
      buttonStyle={{
        background: "#fff",
        border: '1px solid #000',
        color: "#4e503b",
        fontSize: "13px"
      }}
      expires={365}
      overlay
    >
      <p>
        We use cookies on this site to provide personalised content and improve your browsing experience.<br/>
        Certain cookies are used to provide statistics and analyses of our communications. <br/>
        We never share this data with other brands.<br/>
        <br/>
        Accept cookies by clicking "Accept Cookies".<br/>
      </p>
    </CookieConsent>
  </div>
})

class RouteComponent extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    let pct = jwt.decode(props.pct);
    this.state = {
      pct: pct,
      gaTag: pct && !!pct.gaTag
    };
  }

  componentDidMount() {
    const {onSignInSuccess, authState: {isAuthenticated, user}} = this.context;

    const {location, match} = this.props;
    const query = new URLSearchParams(location.search);
    let token = query.get("token");

    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('user', token);
      onSignInSuccess(token);
      this.props.history.push(location.pathname);
    }

    if (isAuthenticated && user && user.email) {
      this.validateUser();
    }

  }

  validateUser = () => {
    const {onSignOut} = this.context;
    const {client} = this.props;
    client.query({
      query: VERIFY_USER,
      fetchPolicy: 'network-only',
    }).then(data => {
      if (!(data && data.data.verifyUser && data.data.verifyUser.verified)) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        onSignOut();
      }
    }).catch(err => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      onSignOut();
    });
  }

  componentDidUpdate() {

    if (this.state.gaTag) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

  }

  componentWillMount() {
    const {gaTag, pct} = this.state;
    if (gaTag) {
      ReactGA.initialize(pct.gaTag);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

  }

  render() {
    const {authState: {isAuthenticated}} = this.context;

    const {location, match} = this.props;
    const query = new URLSearchParams(location.search);
    const redirect = query.get("redirect") || "";

    // if (!isAuthenticated && !['/login', '/signup', '/signUpGuest', '/forgot-password', '/verify/verify'].includes(location.pathname)) {
    //   let history = this.props.history;
    //   if (history && history.location && history.location.state && history.location.state.from && history.location.state.from.pathname) {
    //     this.props.history.push(`/login?redirect=${history.location.state.from.pathname}${history.location.state.from.search}`);
    //   }
    // }

    // console.log("isAuthenticated = ", isAuthenticated);
    // console.log("location.pathname = ", location.pathname);
    // console.log("location = ", location.search);
    // console.log("redirect = ", redirect);

    if (!isAuthenticated && ['/signup', '/login', '/forgot-password', '/login/verify'].includes(location.pathname)) {
      // console.log("A1");
      let history = this.props.history;
      if (history && history.location && history.location.state && history.location.state.from && history.location.state.from.pathname && history.location.state.from.pathname !== "/") {
        // console.log("A1.1");
        this.props.history.push(`${location.pathname}?redirect=${history.location.state.from.pathname}${history.location.state.from.search}`);
      }
    }

    if (isAuthenticated && ['/login', '/signup', '/forgot-password', '/login/verify'].includes(location.pathname)) {
      // console.log("D1");
      if (redirect !== "") {
        // console.log("D1.1");
        this.props.history.push(redirect);
      } else {
        // console.log("D1.2");
        this.props.history.push(`/`);
      }
      return "";
    }

    return <>
      <Switch>
        {/*<Route path={`${process.env.PUBLIC_URL}/elements`} component={ElementPages}/>*/}
        {/*<Route path={`${process.env.PUBLIC_URL}/product`} component={ProductPages}/>*/}
        {/*<Route path={`${process.env.PUBLIC_URL}/collections`} component={CollectionPages}/>*/}
        {/*<Route path={`${process.env.PUBLIC_URL}/virtualshowrooms`} component={VirtualShowroomsPages}/>*/}
        {/*<Route path={`${process.env.PUBLIC_URL}/shop`} component={ShopPages}/>*/}
        {/*<Route path={`${process.env.PUBLIC_URL}/blog`} component={BlogPages}/>*/}
        {/*<Route path={`${process.env.PUBLIC_URL}/pages`} component={OtherPages}/>*/}

        <GuestRoute path={`${process.env.PUBLIC_URL}/collections`}>
          <CollectionPages/>
        </GuestRoute>

        <GuestRoute path={`${process.env.PUBLIC_URL}/showrooms`}>
          <VirtualShowroomPages/>
        </GuestRoute>

        <GuestRoute path={`${process.env.PUBLIC_URL}/shop-the-room`}>
          <ShopTheLookPages/>
        </GuestRoute>

        {/*{*/}
        {/*  isAuthenticated ? <PrivateRoute path={`${process.env.PUBLIC_URL}/quotations`}>*/}
        {/*    <QuotationPages/>*/}
        {/*  </PrivateRoute> : <GuestRoute path={`${process.env.PUBLIC_URL}/quotations`}>*/}
        {/*    <QuotationPages loadOnlyDetailRoute={true}/>*/}
        {/*  </GuestRoute>*/}
        {/*}*/}

        <GuestRoute path={`${process.env.PUBLIC_URL}/quotations`}>
          <QuotationPages loadOnlyDetailRoute={true}/>
        </GuestRoute>

        {/*{*/}
        {/*  isAuthenticated && <PrivateRoute path={`${process.env.PUBLIC_URL}/profile`}>*/}
        {/*    <QuotationPages/>*/}
        {/*  </PrivateRoute>*/}
        {/*}*/}

        <GuestRoute path={`${process.env.PUBLIC_URL}/wishlist`}>
          <WishListPages/>
        </GuestRoute>

        <GuestRoute path={`${process.env.PUBLIC_URL}/cart`}>
          <CartPages/>
        </GuestRoute>

        {/*<Route path={`${process.env.PUBLIC_URL}/login`} component={LoginPage}/>*/}
        {/*<Route path={`${process.env.PUBLIC_URL}/signup`} component={SignUpPage}/>*/}
        <Route path={`${process.env.PUBLIC_URL}/review-sent`} component={RequestReviewPendingPage}/>
        <Route path={`${process.env.PUBLIC_URL}/review-rejected`} component={RequestReviewRejectedPage}/>
        {/*<Route path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgotPasswordPage}/>*/}
        <Route path={`${process.env.PUBLIC_URL}/about`} component={AboutPage}/>
        <Route path={`${process.env.PUBLIC_URL}/contact`} component={ContactPage}/>
        <Route path={`${process.env.PUBLIC_URL}/event`} component={EventPage}/>
        <Route path={`${process.env.PUBLIC_URL}/quotation-submitted`} component={QuotationSubmittedPage}/>
        <Route path={`${process.env.PUBLIC_URL}/passcode-verified`} component={PasscodeVerifiedPage}/>
        <Route path={`${process.env.PUBLIC_URL}/passcode-sent`} component={PasscodeSentPage}/>
        {/*<AboutRoute/>*/}

        {/*<GuestRoute path={`${process.env.PUBLIC_URL}/shop`}>*/}
        {/*  <ShopPage/>*/}
        {/*</GuestRoute>*/}

        <GuestRoute path={`${process.env.PUBLIC_URL}/products`}>
          <ProductsPage/>
        </GuestRoute>

        <Route exact={true} path={`${process.env.PUBLIC_URL}/404`}>
          <Layout>
            <ErrorPage/>
          </Layout>
        </Route>

        <GuestRoute exact={true} path={`${process.env.PUBLIC_URL}/`}>
          <HomePage/>
        </GuestRoute>
        {/*<Route>*/}
        {/*  <Layout>*/}
        {/*    <ErrorPage/>*/}
        {/*  </Layout>*/}
        {/*</Route>*/}
        <Redirect to={'/404'}/>
      </Switch>
      <BackToTop/>
      <CookiePopup pct={this.state.pct}/>
    </>
  }

}

export default withApolloClient(withRouter(RouteComponent));