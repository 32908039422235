import jwt from "jsonwebtoken";
import {CookieHandler} from "../../utils/CookieHelper";

const isBrowser = typeof window !== 'undefined';

const categories = [
  {
    "_id": "60dee283c39ce91b64da1ea2",
    "title": "Decor",
    "slug": "decor",
    "type": null,
    "icon": "decore_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "60c2f1f7b2e0e4cb829918e3",
    "title": "Bar",
    "slug": "bar",
    "type": null,
    "icon": "barware_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "60c2f1fca1b1dfe14a6441f8",
    "title": "Bath",
    "slug": "bath",
    "type": null,
    "icon": "bath_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "5fb8fb847778951f8c2037bd",
    "title": "Furniture",
    "slug": "furniture",
    "type": null,
    "icon": "all_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "608931532810e4f3ea17e488",
    "title": "Kitchenware",
    "slug": "kitchenware",
    "type": null,
    "icon": "table_top_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "6110d35b8acee88e1aa7eb36",
    "title": "Lighting",
    "slug": "lighting",
    "type": null,
    "icon": "lighting_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "60893167327d5e253d19676e",
    "title": "Pet",
    "slug": "pet",
    "type": null,
    "icon": "pet_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "6089318861717ea84b9420ca",
    "title": "Outdoor",
    "slug": "outdoor",
    "type": null,
    "icon": "outdoor_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "608931c651b0699e4403a9f8",
    "title": "Storage",
    "slug": "storage",
    "type": null,
    "icon": "storage_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "608931c22f5f488d8ad086fb",
    "title": "Table Top",
    "slug": "table-top",
    "type": null,
    "icon": "decor_icon.png",
    "__typename": "CategoriesPayload"
  },
  {
    "_id": "62bae48307c25150ab078a4d",
    "title": "Grander",
    "slug": "Grander",
    "icon": "decor_icon.png"
  },
  {
    "_id": "62bae72c6d72c78bef09ce0f",
    "title": "Serveware",
    "slug": "Serveware",
    "icon": "decor_icon.png"
  },
  {
    "_id": "62bae7e5834951afd0408820",
    "title": "Dinnerware",
    "slug": "Dinnerware",
    "icon": "decor_icon.png"
  }
];
const subCategories = [
  {
    "_id" : "62baf3d1133747ead86274ff",
    "title" : "Coffee Table",
    "slug" : "Coffee-Table",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "5fb8fb847778951f8c2037bd"
  },
  {
    "_id" : "62baf3d671bda6a41ff4a9cb",
    "title" : "Console Table",
    "slug" : "Console-Table",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "5fb8fb847778951f8c2037bd"
  },
  {
    "_id" : "62baf3da5bead3461059c4e4",
    "title" : "End Table",
    "slug" : "End-Table",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "5fb8fb847778951f8c2037bd"
  },
  {
    "_id" : "62baf3dfa4719521c4d36c3d",
    "title" : "Side Table",
    "slug" : "Side-Table",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "5fb8fb847778951f8c2037bd"
  },
  {
    "_id" : "62baf3e4f6f9d410377da14a",
    "title" : "Night Stand",
    "slug" : "Night-Stand",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "5fb8fb847778951f8c2037bd"
  },
  {
    "_id" : "62baf3e9445a81c7902e971b",
    "title" : "Benches",
    "slug" : "Benches",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "5fb8fb847778951f8c2037bd"
  },
  {
    "_id" : "62baf3edb424a5b4ea813cfa",
    "title" : "Stools",
    "slug" : "Stools",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "5fb8fb847778951f8c2037bd"
  },
  {
    "_id" : "62baf3f26681c605ad5ca71d",
    "title" : "Desk",
    "slug" : "Desk",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "5fb8fb847778951f8c2037bd"
  },
  {
    "_id" : "62baf3f6323403020883d83a",
    "title" : "Shelf",
    "slug" : "Shelf",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "608931c651b0699e4403a9f8"
  },
  {
    "_id" : "62baf3fa2c7afc1c6f0d2067",
    "title" : "Display Ledge",
    "slug" : "Display-Ledge",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "608931c651b0699e4403a9f8"
  },
  {
    "_id" : "62baf3fe981123e64f4e47a7",
    "title" : "Hooks",
    "slug" : "Hooks",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "608931c651b0699e4403a9f8"
  },
  {
    "_id" : "62baf402068aaea6db670211",
    "title" : "oranizer",
    "slug" : "oranizer",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "608931c651b0699e4403a9f8"
  },
  {
    "_id" : "62baf406475bcaa8e1763ff0",
    "title" : "Rack",
    "slug" : "Rack",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "608931c651b0699e4403a9f8"
  },
  {
    "_id" : "62baf40a9fd96dd09d960ec0",
    "title" : "Jwellery Box",
    "slug" : "Jwellery-Box",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "608931c651b0699e4403a9f8"
  },
  {
    "_id" : "62baf40e5bea6680fa251f3e",
    "title" : "Pet Storage",
    "slug" : "Pet-Storage",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "608931c651b0699e4403a9f8"
  },
  {
    "_id" : "62baf412866d3300d0f5c2d5",
    "title" : "Mirror",
    "slug" : "Mirror",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf417a067acf5aaa02952",
    "title" : "Wall Art",
    "slug" : "Wall-Art",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf41b5eaa56146eb12e57",
    "title" : "Frames",
    "slug" : "Frames",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf41e00a70b53537dce81",
    "title" : "Clock",
    "slug" : "Clock",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf42371b5124956430771",
    "title" : "Wall Organization",
    "slug" : "Wall-Organization",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf428d4178e1725151829",
    "title" : "Vase",
    "slug" : "Vase",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf42cfc9bb5ac1b4d11a2",
    "title" : "Candale Stand",
    "slug" : "Candale-Stand",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf432215d695ae0728afa",
    "title" : "Lantern",
    "slug" : "Lantern",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf436cd70713a3895e3a2",
    "title" : "Planter",
    "slug" : "Planter",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf43ba90e97f3c03d38a8",
    "title" : "Object",
    "slug" : "Object",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "60dee283c39ce91b64da1ea2"
  },
  {
    "_id" : "62baf4406eaef0462ba673c5",
    "title" : "Planter",
    "slug" : "g_Planter",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae48307c25150ab078a4d"
  },
  {
    "_id" : "62baf445989a856a7e50c6a9",
    "title" : "Lanter",
    "slug" : "Lanter",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae48307c25150ab078a4d"
  },
  {
    "_id" : "62baf44a770d87ead2c8cd04",
    "title" : "Water Can",
    "slug" : "Water-Can",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae48307c25150ab078a4d"
  },
  {
    "_id" : "62baf44fac0d7854f8377ba6",
    "title" : "Cantern",
    "slug" : "Cantern",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae48307c25150ab078a4d"
  },
  {
    "_id" : "62baf4535127ecdfbbc1bcb7",
    "title" : "Hurrican",
    "slug" : "Hurrican",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae48307c25150ab078a4d"
  },
  {
    "_id" : "62baf457d1c692ef00b22c5b",
    "title" : "Tools And Utensils",
    "slug" : "Tools And Utensils",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "608931532810e4f3ea17e488"
  },
  {
    "_id" : "62baf45c0b038d6ef057802a",
    "title" : "Cullerry",
    "slug" : "Cullerry",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "608931532810e4f3ea17e488"
  },
  {
    "_id" : "62baf460e6e8e48c3a41d3ae",
    "title" : "Serving Tray",
    "slug" : "Serving-Tray",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae72c6d72c78bef09ce0f"
  },
  {
    "_id" : "62baf464d07d731d29d868b0",
    "title" : "Serving Platters",
    "slug" : "Serving-Platters",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae72c6d72c78bef09ce0f"
  },
  {
    "_id" : "62baf4689d989c8779384057",
    "title" : "Serving Bowls",
    "slug" : "Serving-Bowls",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae72c6d72c78bef09ce0f"
  },
  {
    "_id" : "62baf46cd353e2e8b33431fa",
    "title" : "Cheese Boards",
    "slug" : "Cheese-Boards",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae72c6d72c78bef09ce0f"
  },
  {
    "_id" : "62baf46f19bb97c459275504",
    "title" : "Sancks Bowl",
    "slug" : "Sancks-Bowl",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae72c6d72c78bef09ce0f"
  },
  {
    "_id" : "62baf474aeaae5283fcfe60c",
    "title" : "Sancks Server",
    "slug" : "Sancks-Server",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae72c6d72c78bef09ce0f"
  },
  {
    "_id" : "62baf47927598d37160d51b6",
    "title" : "Serving Utensils",
    "slug" : "Serving-Utensils",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae72c6d72c78bef09ce0f"
  },
  {
    "_id" : "62baf47df1927c903a81bd04",
    "title" : "Chopping Board",
    "slug" : "Chopping-Board",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae72c6d72c78bef09ce0f"
  },
  {
    "_id" : "62baf4811802f652f33dece3",
    "title" : "Chip & Dips",
    "slug" : "Chip-n-Dips",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae72c6d72c78bef09ce0f"
  },
  {
    "_id" : "62baf485baf3680af35fa610",
    "title" : "DinnerWare Set",
    "slug" : "DinnerWare-Set",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf48980f920f73abc6303",
    "title" : "Bowls",
    "slug" : "Bowls",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf48de2f56a85612a3220",
    "title" : "Appetizer",
    "slug" : "Appetizer",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf4905b899086b43cfe64",
    "title" : "Salad Platters / Server",
    "slug" : "Salad-Platters-o-Server",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf4947f3eb2c05c3b5b8a",
    "title" : "Napkin Ring / Holder",
    "slug" : "Napkin-Ring-o-Holder",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf499cfb773e6c80d9931",
    "title" : "Coasters",
    "slug" : "Coasters",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf49ce296c80d91c964a4",
    "title" : "Dome",
    "slug" : "Dome",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf4a141c95e24cd2bbb19",
    "title" : "Cake Stand",
    "slug" : "Cake-Stand",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf4a5459c2f9942c4d31e",
    "title" : "Tier Stand",
    "slug" : "Tier-Stand",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf4a9831ce96d0d5efec0",
    "title" : "Platter",
    "slug" : "Platter",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf4ad529da51083adfba8",
    "title" : "Tray",
    "slug" : "Tray",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf4b218435596afc44de9",
    "title" : "Basket",
    "slug" : "Basket",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  },
  {
    "_id" : "62baf4b7981a74315415ce1a",
    "title" : "Lazy Susan",
    "slug" : "Lazy-Susan",
    "icon" : "decore_icon.png",
    "parentCategoryId" : "62bae7e5834951afd0408820"
  }
]

export const initialState = () => {
  let pct = undefined;
  try {
    pct = isBrowser && jwt.decode(localStorage.getItem("pct"));
  } catch (e) {

  }
  return {
    searchTerm: '',
    isSticky: false,
    isSidebarSticky: true,
    isDrawerOpen: false,
    isModalOpen: false,
    appAuthenticated: !!pct,
    pct,
    companyName: pct ? pct.name : "",
    currencySymbol: ((pct && pct.currency && pct.currency.symbol) || ""),
    categories: categories,
    subCategories: subCategories,
    materialCategories: ((pct && pct.materialCategories) || []),
    banners: ((pct && pct.banners) || []),
    socialLinks: ((pct && pct.socialLinks) || []),
    addToCartBtnText: ((pct && pct.productSettings && pct.productSettings.addToCartBtnText) || "Add To Cart"),
    viewCartBtnText: ((pct && pct.productSettings && pct.productSettings.viewCartBtnText) || "View Cart"),
  }
};

export function appReducer(state, action) {
  switch (action.type) {
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };
    case 'SET_STICKY':
      return {
        ...state,
        isSticky: true,
      };
    case 'REMOVE_STICKY':
      return {
        ...state,
        isSticky: false,
      };
    case 'SET_SIDEBAR_STICKY':
      return {
        ...state,
        isSidebarSticky: true,
      };
    case 'REMOVE_SIDEBAR_STICKY':
      return {
        ...state,
        isSidebarSticky: false,
      };
    case 'TOGGLE_DRAWER':
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
      };
    case 'TOGGLE_MODAL':
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    case 'SET_PCT':
      localStorage.setItem('pct', action.payload);
      let decoded = isBrowser && !!action.payload ? jwt.decode(action.payload) : undefined;
      let guestLogin = CookieHandler.getCookieValue('guestLogin');

      if (!guestLogin && !!(decoded.customerLoginPreferences && decoded.customerLoginPreferences.loginRequired)) {
        CookieHandler.setCookie('guestLogin', true, 365);
      }

      return {
        ...state,
        appAuthenticated: !!decoded,
        pct: decoded,
        companyName: decoded && decoded.name || "",
        currencySymbol: decoded.currency && decoded.currency.symbol || "",
        categories: categories || [],
        subCategories: subCategories || [],
        materialCategories: decoded.materialCategories || [],
        banners: decoded.banners || [],
        socialLinks: ((decoded.socialLinks) || []),
        addToCartBtnText: ((decoded.productSettings && decoded.productSettings.addToCartBtnText) || "Add To Cart"),
        viewCartBtnText: ((decoded.productSettings && decoded.productSettings.viewCartBtnText) || "View Cart"),
      };
    default: {
      throw new Error(`Unsupported action type at App Reducer`);
    }
  }
}
