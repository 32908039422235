import {gql} from "@apollo/client";

export const CHECK_DOMAIN = gql`
  query {
    check {
      pct
    }
  }
`;

export const VERIFY_USER = gql`
  query {
    verifyUser {
      verified
    }
  }
`;