import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {useQuery} from '@apollo/client';
import {Provider} from 'react-redux';
import {BrowserRouter, useHistory} from 'react-router-dom';
import 'react-app-polyfill/ie11';
import CustomApolloProvider from './components/apollo-provider';
import {AppProvider} from './contexts/app/app.provider';

import {PersistGate} from 'redux-persist/integration/react';
// import store
import store, {persistor} from './redux/store';
// import action
import {getAllProducts, refreshStore} from './redux/actions';
// import routes
import AppRoute from './routes';
// import Utils
import {initFunctions} from './utils';

import LoadingOverlay from './components/features/loading-overlay';
import {CHECK_DOMAIN} from "./graphql/query/check.query";
import './custom.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {checkWebPSupport} from "./utils/Utils";

const SubRoot = () => {

  const history = useHistory();
  const {loading, error, data} = useQuery(CHECK_DOMAIN);

  if (loading) return <div></div>;
  // if (error) return `Error! ${error.message}`;
  if (error) {
    history.push('/404');
  }

  return <BrowserRouter basename={'/'}>
    <AppRoute pct={data && data.check && data.check.pct}/>
    <ToastContainer />
  </BrowserRouter>

}

export function Root() {
  initFunctions();
  checkWebPSupport();
  store.dispatch(getAllProducts());

  useEffect(() => {
    if (store.getState().modal.current !== 7) {
      store.dispatch(refreshStore(7));
    }

  }, [])

  return (
    <CustomApolloProvider>
      <AppProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={<LoadingOverlay/>}>
            <BrowserRouter basename={'/'}>
              <SubRoot/>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </AppProvider>
    </CustomApolloProvider>
  );
}

ReactDOM.render(<Root/>, document.getElementById('root'));