import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {getCartTotal} from '../../../services';
import {removeFromCart} from '../../../redux/actions';
import {useAppState} from "../../../contexts/app/app.provider";

function CartMenuSidebar(props) {

  const pct = useAppState('pct');
  const currencySymbol = useAppState('currencySymbol');
  const showPrice = pct && pct.productSettings && pct.productSettings.showPrice;
  const showQuantity = pct && pct.productSettings && pct.productSettings.showQuantity;
  const viewCartBtnText = useAppState('viewCartBtnText');

  const {cartlist, removeFromCart} = props;
  let total = getCartTotal(cartlist);

  return (
    <>
      <div className="module-content module-hamburger cart-box">
        <a
          className="module-cancel cursor-pointer"
          onClick={(e) => {
            e && e.preventDefault();
            props.toggleCartVisibility(!props.cartVisible);
          }}
        >
          CLOSE
        </a>
        <div className="cart-title">
          <h5>Shopping Cart</h5>
        </div>

        <div className="cart-overview">
          {
            0 === cartlist.length ? <p>No products in the cart.</p> : <>
              <ul className="list-unstyled">
                {
                  cartlist.map((item, index) => {
                    return <li key={index}>
                      <img className="img-fluid" src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`}
                           alt={item.name}/>
                      <div className="product-meta">
                        <h5 className="product-title text-truncate mr-4">{item.name}</h5>
                        {
                          showQuantity && <p className="product-qunt">Quantity: {item.qty}</p>
                        }
                        {
                          showPrice && <p className="product-price">
                            {currencySymbol}
                            {(item.priceValue || 0).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                          </p>
                        }
                      </div>
                      <Link
                        className="cart-cancel"
                        to="#"
                        onClick={e => {
                          e && e.preventDefault();
                          removeFromCart(item.productId);
                        }}
                      >
                        <i className="lnr lnr-cross"></i>
                      </Link>
                    </li>
                  })
                }
                {/*<li>*/}
                {/*  <img className="img-fluid" src="assets/images/products/thumb/1.jpg"*/}
                {/*       alt="product"/>*/}
                {/*  <div className="product-meta">*/}
                {/*    <h5 className="product-title">Hebes Great Chair</h5>*/}
                {/*    <p className="product-qunt">Quantity: 01</p>*/}
                {/*    <p className="product-price">$24.00</p>*/}
                {/*  </div>*/}
                {/*  <Link className="cart-cancel" to="#"><i className="lnr lnr-cross"></i></Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <img className="img-fluid" src="assets/images/products/thumb/2.jpg"*/}
                {/*       alt="product"/>*/}
                {/*  <div className="product-meta">*/}
                {/*    <h5 className="product-title">Hebes Great Chair</h5>*/}
                {/*    <p className="product-qunt">Quantity: 01</p>*/}
                {/*    <p className="product-price">$24.00</p>*/}
                {/*  </div>*/}
                {/*  <Link className="cart-cancel" to="#"><i className="lnr lnr-cross"></i></Link>*/}
                {/*</li>*/}
              </ul>
            </>
          }
        </div>

        {
          0 !== cartlist.length && <>
            {
              showPrice && <div className="cart-total">
                <div className="total-desc">
                  Sub total
                </div>
                <div className="total-price">
                  {currencySymbol}
                  {(total || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </div>
              </div>
            }
            <div className="clearfix">
            </div>
            <div className="cart--control">
              <Link className="btn btn--white btn--bordered btn--rounded" to={`${process.env.PUBLIC_URL}/cart`}>
                {viewCartBtnText}
              </Link>
              {/*<Link className="btn btn--primary btn--rounded" to="#">Checkout</Link>*/}
            </div>
          </>
        }

      </div>

      {/*<div className="dropdown cart-dropdown">*/}
      {/*  <Link to={`${process.env.PUBLIC_URL}/cart`} className="dropdown-toggle" role="button" data-toggle="dropdown"*/}
      {/*        aria-haspopup="true" aria-expanded="false" data-display="static">*/}
      {/*    <i className="icon-shopping-cart"></i>*/}
      {/*    <span className="cart-count">{getCartCount(cartlist)}</span>*/}
      {/*  </Link>*/}

      {/*  <div className={`dropdown-menu dropdown-menu-right ${cartlist.length === 0 ? 'text-center' : ''}`}>*/}
      {/*    {*/}
      {/*      0 === cartlist.length ?*/}
      {/*        <p>No products in the cart.</p> :*/}
      {/*        <>*/}
      {/*          <div className="dropdown-cart-products">*/}
      {/*            {cartlist.map((item, index) => (*/}
      {/*              <div className="product" key={index}>*/}
      {/*                <div className="product-cart-details">*/}
      {/*                  <h4 className="product-title">*/}
      {/*                    <Link to={`${process.env.PUBLIC_URL}/products/${item.productId}`}*/}
      {/*                          dangerouslySetInnerHTML={safeContent(item.name)}></Link>*/}
      {/*                  </h4>*/}

      {/*                  <span className="cart-product-info">*/}
      {/*                  <span className="cart-product-qty">{item.qty}</span>*/}
      {/*                    {*/}
      {/*                      showPrice && item.priceValue ? <Fragment>*/}
      {/*                        x {currencySymbol}*/}
      {/*                        {item.priceValue && item.priceValue.toLocaleString(undefined, {*/}
      {/*                          minimumFractionDigits: 2,*/}
      {/*                          maximumFractionDigits: 2*/}
      {/*                        })}*/}
      {/*                        /!*x ${!!item.discount ? item.salePrice.toLocaleString(undefined, {*!/*/}
      {/*                        /!*  minimumFractionDigits: 2,*!/*/}
      {/*                        /!*  maximumFractionDigits: 2*!/*/}
      {/*                        /!*}) : item.priceValue.toLocaleString(undefined, {*!/*/}
      {/*                        /!*  minimumFractionDigits: 2,*!/*/}
      {/*                        /!*  maximumFractionDigits: 2*!/*/}
      {/*                        /!*})}*!/*/}
      {/*                      </Fragment> : ''*/}
      {/*                    }*/}
      {/*                </span>*/}
      {/*                </div>*/}

      {/*                <figure className="product-image-container">*/}
      {/*                  <Link to={`${process.env.PUBLIC_URL}/products/${item.productId}`} className="product-image">*/}
      {/*                    <img src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`}*/}
      {/*                         data-oi={`${process.env.CLOUD_FRONT_URL}${item.thumb}`} alt={item.name}/>*/}
      {/*                  </Link>*/}
      {/*                </figure>*/}
      {/*                <button className="btn-remove" title="Remove Product"*/}
      {/*                        onClick={() => removeFromCart(item.productId)}>*/}
      {/*                  <i className="icon-close"></i>*/}
      {/*                </button>*/}
      {/*              </div>*/}
      {/*            ))}*/}
      {/*          </div>*/}
      {/*          {*/}
      {/*            showPrice ? <div className="dropdown-cart-total">*/}
      {/*              <span>Total</span>*/}

      {/*              <span className="cart-total-price">{currencySymbol}{total.toLocaleString(undefined, {*/}
      {/*                minimumFractionDigits: 2,*/}
      {/*                maximumFractionDigits: 2*/}
      {/*              })}</span>*/}
      {/*            </div> : ''*/}
      {/*          }*/}

      {/*          <div className="dropdown-cart-action">*/}
      {/*            <Link to={`${process.env.PUBLIC_URL}/cart`} className="btn btn-primary btn-block">View Cart</Link>*/}
      {/*            /!*<Link to={`${process.env.PUBLIC_URL}/cart`}*!/*/}
      {/*            /!*      className="btn btn-outline-primary-2">*!/*/}
      {/*            /!*  <span>Checkout</span>*!/*/}
      {/*            /!*  <i className="icon-long-arrow-right"></i>*!/*/}
      {/*            /!*</Link>*!/*/}
      {/*          </div>*/}
      {/*        </>*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
}

function mapStateToProps(state) {
  return {
    cartlist: state.cartlist.cart ? state.cartlist.cart : []
  }
}

export default connect(mapStateToProps, {removeFromCart})(CartMenuSidebar);