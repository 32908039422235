import React, {useEffect} from 'react';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {useAppDispatch, useAppState} from "../contexts/app/app.provider";
import {AuthProvider} from '../contexts/auth/auth.provider';

import LoadingOverlay from '../components/features/loading-overlay';
import RouteComponent from './RouteComponent';

export default function AppRoot({pct}) {

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (pct) {
      dispatch({type: 'SET_PCT', payload: pct});
    }
  }, [])

  let history = useHistory();

  useEffect(() => {
    let query = new URLSearchParams(window.location.search);
    let path = query.get("path") || "";
    // path = JSON.stringify(path).replace(/\"/g,'');
    // console.log(`react path ${new Date().getTime()} = `,path);
    // alert(path)
    // path = path.replace(/\/demo7/g,'')
    if (path && path.length) {
      // console.log('in = ', path)
      history.push(path)
    }
  }, []);

  return (
    <AuthProvider>
      <React.Suspense fallback={<LoadingOverlay/>}>
        <RouteComponent pct={pct}/>
      </React.Suspense>
    </AuthProvider>
  )
}