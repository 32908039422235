// Get Products
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';

// Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE';

//Quick     
export const SHOW_QUICKVIEW = 'SHOW_QUICKVIEW';
export const CLOSE_QUICKVIEW = 'CLOSE_QUICKVIEW';

// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CHANGE_QTY = 'CHANGE_QTY';
export const CHANGE_SHIPPING = 'CHANGE_SHIPPING';
export const CLEAR_CART = 'CLEAR_CART';

// WishList
export const TOGGLE_WISHLIST = 'TOGGLE_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';
export const RESET_COMPARE = 'RESET_COMPARE';

//Product Filters
export const SORT_BY = 'SORT_BY';
export const CATEGORY_FILTER = 'CATEGORY_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const SIZE_FILTER = 'SIZE_FILTER';
export const COLOR_FILTER = 'COLOR_FILTER';
export const BRAND_FILTER = 'BRAND_FILTER';
export const PRICE_FILTER = 'PRICE_FILTER';
export const RATING_FILTER = 'RATING_FILTER';
export const MATERIAL_CATEGORY_FILTER = 'MATERIAL_CATEGORY_FILTER';

//Newsletter
export const HIDE_NEWSLETTER_MODAL = 'HIDE_NEWSLETTER_MODAL';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REMOVE_NEWSLETTER = 'REMOVE_NESLETTER';

/* GLTF */
export const SET_MODEL_URL = 'SET_MODEL_URL';
export const SET_FULL_STYLE = 'SET_FULL_STYLE';
export const SET_FINISH_TO_PART = 'SET_FINISH_TO_PART';
export const SET_MODEL_PART = 'SET_MODEL_PART';
export const GET_MODEL_PART = 'GET_MODEL_PART';
export const SET_MODEL_PART_LIST = 'SET_MODEL_PART_LIST';
export const SET_MODEL_SCREEN_SHOT = 'SET_MODEL_SCREEN_SHOT';
export const TAKE_MODEL_SCREEN_SHOT = 'TAKE_MODEL_SCREEN_SHOT';
export const RESET_VIEWER_DATA = 'RESET_VIEWER_DATA';
export const SET_STYLE_COUNT = 'SET_STYLE_COUNT';
export const REFETCH_STYLE_LIST = 'REFETCH_STYLE_LIST';
export const UPDATE_FINISH_IN_PART = 'UPDATE_FINISH_IN_PART';
export const TEST_FINISH = 'TEST_FINISH';
export const UPDATE_COLOR_IN_PART = 'UPDATE_COLOR_IN_PART';
export const UPDATE_PATTERN_IN_PART = 'UPDATE_PATTERN_IN_PART';
export const UPDATE_PATTERN_AND_COLOR_IN_PART = 'UPDATE_PATTERN_AND_COLOR_IN_PART';
export const SET_TOGGLE_PART_ACCORDION = 'SET_TOGGLE_PART_ACCORDION';
export const RESET_PARTS_BY_LIST = 'RESET_PARTS_BY_LIST';
export const SET_GENERATE_GLTF = 'SET_GENERATE_GLTF';
export const SET_GENERATE_GLTF_WITH_COMPANY = 'SET_GENERATE_GLTF_WITH_COMPANY';
export const SET_3D_COMPONENT = 'SET_3D_COMPONENT';
export const SET_PRODUCT_DIMENSIONS = 'SET_PRODUCT_DIMENSIONS';
export const TOGGLE_PACKAGE_BOX = 'TOGGLE_PACKAGE_BOX';
export const SET_PACKAGE_BOX_DIMENSION = 'SET_PACKAGE_BOX_DIMENSION';
export const SET_PACKAGE_BOX_OPACITY = 'SET_PACKAGE_BOX_OPACITY';
export const SET_PACKAGE_BOX_MATERIAL = 'SET_PACKAGE_BOX_MATERIAL';
export const SET_PACKAGE_BOX_COLOR = 'SET_PACKAGE_BOX_COLOR';
export const SET_VIEWER_SETTINGS = 'SET_VIEWER_SETTINGS';
export const SET_ANNOTATION_VISIBILITY = 'SET_ANNOTATION_VISIBILITY';
export const SET_SHAPE_TO_STYLE = 'SET_SHAPE_TO_STYLE';
export const SET_REMOVE_SHAPE_FROM_VIEWER = 'SET_REMOVE_SHAPE_FROM_VIEWER';
export const SET_SHAPE_TO_VIEWER = 'SET_SHAPE_TO_VIEWER';
export const SET_ENV_MAP = 'SET_ENV_MAP';
export const SET_BRIGHTNESS_VALUE = 'SET_BRIGHTNESS_VALUE';
export const SET_ZOOM_LOCK = 'SET_ZOOM_LOCK';

export const DONWLOAD_SCREENSHOT = 'DONWLOAD_SCREENSHOT';

export const OPEN_ADD_TO_CART_MODAL = 'OPEN_ADD_TO_CART_MODAL';
export const HIDE_ADD_TO_CART_MODAL = 'HIDE_ADD_TO_CART_MODAL';

export const TOGGLE_ENQUIRY_MODAL = 'TOGGLE_ENQUIRY_MODAL';